import React from "react";
import Link from "gatsby-link";

import FreudeLogoDesktop from "../assets/logo_desktop.svg";
import FreudeLogoMobile from "../assets/logo_mobile.svg";
import LuiseLogo from "../assets/für-luise.svg";

const Header = () => {
  return (
    <header className="site-header">
      <div className="site-header_inner">
        <Link className="logo" to="/" exact="true" activeClassName="active">
          <h1>
            Gesellschaft der Freunde & Förderer des Luisen-Gymnasiums Düsseldorf
          </h1>
          <img className="logo-desktop_img" src={FreudeLogoDesktop} />
          <img className="logo-mobile_img" src={FreudeLogoMobile} />
        </Link>
        <div className="for-elise">
          <a
            className="luisen-logo"
            href="http://www.luisen-gymnasium.de/"
            target="_blank">
            <img src={LuiseLogo} />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
