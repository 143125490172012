import React from "react";
import Link from "gatsby-link";

export function NavDesktop() {
  return (
    <nav className="site-nav_desktop">
      <div className="site-nav_inner">
        <ul>
          <li>
            <Link to="/" exact="true" activeClassName="active">
              Startseite
            </Link>
          </li>
          <li>
            <Link to="/was-wir-tun/" activeClassName="active">
              Was wir tun
            </Link>
          </li>
          <li>
            <Link to="/ihre-unterstuetzung/" activeClassName="active">
              Ihre Unterstützung
            </Link>
          </li>
          <li>
            <Link to="/antraege-infos/" activeClassName="active">
              Anträge &amp; Infos
            </Link>
          </li>
          <li>
            <Link to="/vorstand/" activeClassName="active">
              Vorstand
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export function NavMobile() {
  const [NavMobileExtended, setNavMobileExtended] = React.useState(false);
  return (
    <nav
      className={"site-nav_mobile" + (NavMobileExtended ? " show" : " hidden")}
      onClick={() => setNavMobileExtended((prev) => !prev)}
      onKeyPress={() => setNavMobileExtended((prev) => !prev)}
      role="presentation">
      <div className="hamburger-bar">
        <button
          className={
            "hamburger hamburger--squeeze" +
            (NavMobileExtended ? " is-extended" : "")
          }
          type="button">
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
        <span>MENU</span>
      </div>
      <div className="sub-nav">
        <ul>
          <li>
            <Link to="/" exact="true" activeClassName="active">
              Startseite
            </Link>
          </li>
          <li>
            <Link to="/was-wir-tun/" activeClassName="active">
              Was wir tun
            </Link>
          </li>
          <li>
            <Link to="/ihre-unterstuetzung/" activeClassName="active">
              Ihre Unterstützung
            </Link>
          </li>
          <li>
            <Link to="/antraege-infos/" activeClassName="active">
              Anträge &amp; Infos
            </Link>
          </li>
          <li>
            <Link to="/vorstand/" activeClassName="active">
              Vorstand
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
