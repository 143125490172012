import React from "react";
import Link from "gatsby-link";

import pdf from "../assets/FördervereinLuisen_Satzung.pdf";

export default function Footer() {
  return (
    <div className="site-footer">
      <div className="site-footer_inner">
        <Link to="/impressum" activeClassName="active">
          Impressum
        </Link>
        <Link to="/datenschutz" activeClassName="active">
          Datenschutz
        </Link>
        <a href={pdf} target="_blank" rel="noreferrer">
          Satzung
        </a>
        <a
          href="https://tobiasriemenschneider.com"
          target="_blank"
          rel="noreferrer">
          Made in DUS
        </a>
      </div>
    </div>
  );
}
