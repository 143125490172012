import React from 'react';
import mailIcon from '../assets/icon_email.svg';
import closeIcon from '../assets/icon-close.svg';

// https://www.npmjs.com/package/react-obfuscate
import Obfuscate from 'react-obfuscate';

class MailTeaser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closed: true
    };
    this.toggleClosed = this.toggleClosed.bind(this);
  }

  toggleClosed() {
    this.setState(prevState => ({
      closed: !prevState.closed
    }));
  }

  render() {
    const isClosed = this.state.closed;
    let icon;

    if (isClosed) {
      icon = <img src={mailIcon} onClick={this.toggleClosed} />;
    } else {
      icon = <img src={mailIcon} />;
    }

    return (
      <div className={this.state.closed ? 'mailTeaser closed' : 'mailTeaser'}>
        {icon}
        {/* <a href="#">E-Mail schreiben</a> */}
        <Obfuscate
          email="foerderverein@luisen-gymnasium.de"
          headers={{
            subject: 'Mail von freunde-luisengymnasium.de'
          }}
        >
          E-Mail schreiben
        </Obfuscate>
        <img src={closeIcon} onClick={this.toggleClosed} />
      </div>
    );
  }
}

export default MailTeaser;
