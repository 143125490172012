import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Helmet from "react-helmet";

import Header from "../components/Header";
import { NavDesktop, NavMobile } from "../components/Nav";
import MailTeaser from "../components/MailTeaser";
import Footer from "../components/Footer";

import "typeface-droid-sans";
import "typeface-droid-serif";

import "../styles/styles.scss";

import AppleTouchIcon from "../assets/favicons/apple-touch-icon.png";
import Favicon32 from "../assets/favicons/favicon-32x32.png";
import Favicon16 from "../assets/favicons/favicon-16x16.png";
import SafariPinned from "../assets/favicons/safari-pinned-tab.svg";

export default function Layout({ pageTitle, children }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          stage
        }
      }
    }
  `);
  const stage = data.site.siteMetadata.stage;
  console.log("stage: ", stage);
  console.log("pageTitle: ", pageTitle);

  return (
    <>
      <Helmet>
        {pageTitle ? (
          <title>{`${pageTitle} | Förderverein des Luisen-Gymnasiums Düsseldorf`}</title>
        ) : (
          <title>Förderverein des Luisen-Gymnasiums Düsseldorf</title>
        )}
        {/* Favicon Stuff */}
        <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={Favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={Favicon16} />
        <link rel="mask-icon" href={SafariPinned} color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>

      {stage === "prod" ? (
        <Helmet>
          <meta name="robots" content="index,follow" />
          <meta
            name="title"
            content="Gesellschaft der Freunde und Förderer des Städtischen
              Luisen-Gymnasiums zu Düsseldorf e.V."
          />
          <meta
            name="description"
            content="Der Förderverein des Luisen-Gymnasiums zu Düsseldorf ist eine Gemeinschaft von Eltern, ehemaligen Schüler*innen sowie Lehrer*innen, die auf vielfältige Weise Bildung und Erziehung fördert und das schulische Leben unterstützt. Erfahren Sie, wie Sie uns unterstützen können."
          />
          <meta
            name="google-site-verification"
            content="4DxP8xKUQ7QVYlMnIiZxEKI0bEBvTS4lf04megg2V94"
          />
        </Helmet>
      ) : (
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
      )}
      <Header />
      <NavDesktop />
      <NavMobile />
      <MailTeaser />
      <div className="site-content">
        <div>{children}</div>
      </div>
      <Footer />
    </>
  );
}
